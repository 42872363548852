<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-row>
      <v-col
        v-for="group in permissionsGroup"
        :key="group.id"
        cols="12"
        md="6"
        class="align-self-center"
      >
        <v-list
          flat
          subheader
        >
          <v-subheader>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ group.icon }}
            </v-icon>
            <div> {{ t(`${group.title}`) }} </div>
          </v-subheader>

          <v-list-item-group
            v-model="settings"
            multiple
            active-class=""
          >
            <v-list-item
              v-for="permission in group.permissions"
              :key="permission.id"
              :value="permission.id"
              :disabled="lockSelection"
              @click="lockSelection ? () => lockSelection = true : null"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="secondary"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="overflow-visible">
                    <span v-if="isDev">#{{ permission.id }}</span>
                    {{ $i18n.locale === 'en' ? permission.name_eng : permission.name_spa }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>

    <options-buttom-tab
      :tab-number="tab"
      :is-mode-save-edit="option !== 2"
      :no-save="false"
      :info-required="true"
      :no-cancel="option === 3"
      :no-arrow="true"
      @onCancel="onCancelButton"
      @onSave="onSaveAction"
    >
    </options-buttom-tab>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { getPermissions } from '@api'
import { error } from '@core/utils/toasted'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import usePermissions from '@core/utils/usePermissions'
import { isDev } from '@core/utils'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const settings = ref([])
    const permissions = ref([])
    const permissionsGroup = ref()
    const { t } = useUtils()
    const dataComputed = computed(() => props.dataParams)
    const lockSelection = ref(props.option === 2)

    const { getPermissionsGroup } = usePermissions()

    const fetchPermissions = async () => {
      const responsePermissions = await getPermissions()
      if (responsePermissions.ok) {
        permissions.value = responsePermissions.data

        const respGroup = getPermissionsGroup(permissions.value)
        permissionsGroup.value = respGroup
      } else {
        error(responsePermissions.message.text)
      }
    }

    const onSaveAction = () => {
      if (settings.value.length) {
        const dataSave = dataComputed.value
        dataSave.permissions_int_ids = settings.value
        emit('save', dataComputed.value)
      } else {
        error('Debe seleccionar permisos!')
      }
    }

    const onCancelButton = () => {
      settings.value = []

      emit('cancel')
    }

    onMounted(async () => {
      settings.value = dataComputed.value.permissions_ids
      await fetchPermissions()
    })

    return {
      form,
      settings,
      permissions,
      permissionsGroup,
      dataComputed,
      lockSelection,

      onCancelButton,
      onSaveAction,
      fetchPermissions,
      getPermissionsGroup,
      isDev,

      // validations
      required,
      emailValidator,

      // i18n
      t,
    }
  },
}
</script>

<style scoped>
.overflow-visible {
  white-space: initial !important;
}
</style>
